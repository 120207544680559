.toolSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 30px 0;
}

.toolSection h4 {
    font-family: 'Playfair Display';
    font-weight: bold;
    font-size: 15px;
    line-height: 12px;
}

.tools {
    display: grid;
    grid-template-columns: repeat(3, 70px);
    grid-gap: 30px;
}

.tool {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #828282;
}

.tool img {
    width: 50px;
    height: 50px;
}

@media(min-width: 500px) {
    .tools {
        grid-template-columns: repeat(5, 80px);
    }
    .toolSection h4 {
        font-size: 18px;
        line-height: 150%;
    }
}