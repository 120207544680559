.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.content {
  width: 100%;
  height: 100%;
  margin: 84px 0;
}

.sectionTitle {
  font-family: 'Playfair Display',serif ;
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  color: #25282B;
}

@media(min-width: 500px) {
  .content {
    margin-top: 15px;
  }
  .sectionTitle {
    font-size: 48px;
    line-height: 72px;
  }

  .allProjects div:nth-of-type(odd) img {
    order: 2;
  }
  
  .allProjects div:nth-of-type(odd) div {
    order: 1;
  }
  
  .allProjects div:nth-of-type(even) img {
    order: 1;
  }
  
  .allProjects div:nth-of-type(even) div {
    order: 2;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
