.drawerToggle {
    width: 40px;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-around;
    align-items: center;
    padding: 11px 2px;
    box-sizing: border-box;
    cursor: pointer;
}

@media (min-width: 500px) {
    .drawerToggle {
        display: none;
    }
}

.burgerIcon {
    width: 24px;
    height: 24px;
}