.toolBar {
    height: 69px;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #FFF;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
    box-sizing: border-box;
    z-index: 90;
}
  
.toolBar nav {
  height: 100%;
}


@media(max-width: 499px) {
  .toolBar {
    height: 84px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  }
  .deskTopOnly {
      display: none;
  }
}




