.home {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    padding: 30px;
    text-align: center;
}

.myPhoto {
    width: 200px;
    height: 200px;
    order: 1;
}
.intro {
    display: flex;
    flex-direction: column;
    align-items: center;
    order: 2;
}

.introBtns button:first-child {
    margin-left: 0;
}


.position {
    font-family: 'Nunito', sans-serif;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    text-transform: uppercase;
    color: #00B5DE;
}
.title {
    font-family: 'Playfair Display', serif;
    font-weight: bold;
    font-size: 34px;
    line-height: 120%;
    color: #333333;
}

.summary {
    font-family: 'Nunito', sans-serif;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #828282;
}

@media(min-width: 500px) {
    .home {
      flex-direction: row;
      justify-content: space-evenly;
      padding: 120px 0;
      text-align: start;
   }
   .intro {
        align-items: flex-start;
        padding: 0px;
        width: 35%;
        height: 100%;
        order:1;
   }
   .myPhoto {
    width: 380px;
    height: 380px;
   }
   .position {
    font-size: 20px;
    line-height: 27px;
   }
   .title {
    font-size: 64px;
   }
   .summary {
    font-size: 24px;
    line-height: 36px;
   }
  }