.navItems {
    margin: 0;
    margin-top: 50px;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    height: 100%;
}

@media(min-width: 500px) {
  .navItems {
    flex-direction: row;
    align-items: center;
    margin: 0;
 }

}




