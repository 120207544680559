.navItem {
    margin: 10px 0;
    box-sizing: border-box;
    display: block;
    width: 100%;
  }
  .navItem a {
    color:white;
    text-decoration: none;
    width: 20%;
    box-sizing: border-box;
    display: block;
    text-align: left;
    font-family: 'Playfair Display', sans-serif;
    font-weight: bold;
    font-size: 20px;
    line-height: 120%;
  }
  .navItem a:hover,
  .navItem a:active,
  .navItem a.active {
    border-bottom: 4px solid #00B5DE;
  }

  .buttonLink a:hover,
  .buttonLink a:active,
  .buttonLink a.active {
    border-style: none;
  }

  @media(min-width: 500px) {
    .navItem {
        margin:0;
        display: flex;
        height: 100%;
        width: auto;
        align-items: center;
      }
      .navItem a {
        color: #292F36;
        width: 100%;
        height: 100%;
        padding: 16px 15px;
        border-bottom: 4px solid transparent;
        font-family: 'Raleway', sans-serif;
        font-weight: 500;
        font-size: 18px;
      }

  }

  