.contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 30px 0;
}

.formGroup {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 275px;
}
  
.formGroup input {
    width: 100%;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #E8ECF4;
    box-sizing: border-box;
    border-radius: 8px;
    margin: 8px 0px;
}

.formGroup input:focus {
    outline: none;
}

.formGroup textarea {
    width: 100%;
    height: 160px;
    background: #FFFFFF;
    border: 1px solid #E8ECF4;
    box-sizing: border-box;
    border-radius: 8px;
    margin: 8px 0px;
}

.formGroup textarea:focus {
    outline: none;
}

.formGroup label {
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #25282B;
}

.button {
    width: 100%;
    height: 43px;
    margin: 20px 10px;
    border-radius: 8px;
    cursor: pointer;
}
  
.button:focus {
    outline: none;
}
  
.submitButton {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    color: #FFFFFF;
    background: #00B5DE !important;
    
}
.submitButton:focus {
    outline: none;
}

.contactSocialMedia {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    width: 128px;
    height: 65px;
}

.contactSocialMedia p {
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #828282;
}

.socialMedia {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.socialMedia a img {
   width: 30px;
   height: 30px;
}

.invalid {
    border: 1px solid #DA1414;
}

.successMessage {
    color: #287D3C;
}

@media(min-width: 500px) { 
    .formGroup {
        width: 400px;
    }

    .contactSocialMedia {
        width: 192px;
        height: 102px;
    }

    .socialMedia a img {
        width: 48px;
        height: 48px;
    }
}
