.project {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    background-color: white;
    margin: 30px;
    padding: 30px;
}

.projectIntro {
    display: flex;
    flex-direction: column;
    text-align: start;
    align-items: flex-start;
}

.projectIntro button {
    margin-left: 0;
}
.projectName {
    font-family: 'Playfair Display', serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 150%;
    color: #25282B;

}

.projectDescription {
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #828282;

}

.project img {
    width: 270px;
    height: 183px;
}

.links {
    display: flex;
    align-items: center;
}

.links a img {
    width: 32px;
    height: 32px;
}

@media(min-width: 500px) {
    .project {
      flex-direction: row;
      justify-content: center;
      border-radius: 24px;
      margin: 50px 0;
      padding: 0;
      background: none !important;
      box-shadow: none;
    }
    .projectIntro {
        width: 400px;
        height: 480px;
        background: #FFFFFF;
        border-bottom-left-radius: 11px;
        border-top-left-radius: 11px;
        padding: 30px;
        box-sizing: border-box;
    }

    .project img {
        width: 450px;
        height: 480px;
        filter: drop-shadow(0px 6px 64px rgba(112, 144, 176, 0.1));
    }
    .projectName {
        font-size: 40px;
        line-height: 150%;
    }
    .projectDescription{
        font-size: 18px;
        line-height: 150%;
    }
    .projectIntro button {
        font-size: 18px;
        line-height: 150%;
    }
}