.sideDrawer {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 200;
    background-color: #052B3E;
    padding: 32px 16px;
    box-sizing: border-box;
    transition: transform .3s ease-out;
  }

  .sideDrawer nav {
    width: 100%;
    height: 100%;
  }

  .closeButton {
    position: absolute;
    left: 16px;
    top: 50px;
    width: 24px;
    height: 24px;
    opacity: 1;
    cursor: pointer;
  }
  .closeButton:hover {
    opacity: 0.3;
  }


  @media (min-width:500px) {
    .sideDrawer {
      display: none;
    }
  }
  
  .open {
    transform: translateX(0);
  }
  
  .close {
    transform: translateX(-100%);
  }
  
