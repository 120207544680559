.button {
    height: 43px;
    background-color: transparent;
    border: none;
    color: white;
    outline: none;
    cursor: pointer;
    font: inherit;
    padding: 8px 24px;
    margin: 10px;
    text-align: center;
    vertical-align: middle;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 18px;
    box-sizing: border-box;
    
}

.primaryBtn {
    width: 115px;
    background: #00B5DE;
    border-radius: 8px;
}
.secondaryBtn {
    border: 1px solid #25282B;
    border-radius: 8px;
    color : #25282B;
    line-height: 150%;
}

